<script>
import EmailUpdate from '@app/components/Admin/EmailUpdate.vue';
import CertificateCleaner from '@app/components/Admin/CertificateCleaner.vue';
import PaymentProviderDisconnect from '@app/components/Admin/PaymentProviderDisconnect.vue';

export default {
  components: {
    PaymentProviderDisconnect,
    CertificateCleaner,
    EmailUpdate,
  },
};
</script>

<template>
  <section>

    <header class="level">
      <div class="level-left is-block w-60p">
        <h1 class="title is-size-4-mobile mb-2">
          Opérations d'administration
        </h1>
        <hr>
        <EmailUpdate/>
        <!-- Utilisation du composant générique pour Stripe -->
        <PaymentProviderDisconnect
          providerName="Stripe"
          apiMethod="disconnectStripe"
        />
        <!-- Utilisation du même composant générique pour PayPal -->
        <PaymentProviderDisconnect
          providerName="PayPal"
          apiMethod="disconnectPaypal"
        />
        <CertificateCleaner/>
      </div>
    </header>
  </section>
</template>
