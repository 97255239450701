<template>
  <AppToggleSection>
    <template #title>
      Déconnexion {{ providerName }}
    </template>
    <template #default>
      <form @submit.prevent="disconnectProvider">
        <b-field label="Adresse email du propriétaire">
          <b-input
            v-model="ownerEmail"
            placeholder="adresse email du propriétaire du compte"
            type="email"
            required
          />
        </b-field>
        <div class="buttons is-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Déconnecter {{ providerName }}
          </b-button>
        </div>
      </form>
    </template>
  </AppToggleSection>
</template>

<script>
import APIAdmin from '@app/services/API/Admin';

export default {
  name: 'PaymentProviderDisconnect',
  props: {
    // Nom du fournisseur de paiement (Stripe, PayPal, etc.)
    providerName: {
      type: String,
      required: true,
    },
    // Nom de la méthode API à appeler dans APIAdmin
    apiMethod: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ownerEmail: '',
      isLoading: false,
    };
  },
  methods: {
    disconnectProvider() {
      this.isLoading = true;
      const data = { owner_email: this.ownerEmail };

      // Appel dynamique de la méthode API basée sur la prop apiMethod
      APIAdmin[this.apiMethod](data)
        .then(() => {
          this.$buefy.dialog.alert(`
            Le compte ${this.providerName} a bien été déconnecté !
          `);
          this.ownerEmail = '';
        })
        .catch((error) => {
          console.error(`Erreur lors de la déconnexion de ${this.providerName}:`, error);
          this.$buefy.toast.open({
            message: `Erreur lors de la déconnexion de ${this.providerName}`,
            type: 'is-danger',
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
