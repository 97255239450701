<template>
  <AppToggleSection>
    <template #title>
      Clean certificat de réussite
    </template>
    <template #default>
      <form @submit.prevent="cleanSelectedFormations">
        <b-field label="Adresse email du propriétaire">
          <b-input
            v-model="ownerEmail"
            placeholder="adresse email du propriétaire du compte"
            type="email"
            required
          />
        </b-field>
        <b-field label="Adresse email de l'apprenant">
          <b-input
            v-model="studentEmail"
            placeholder="adresse email de l'apprenant"
            type="email"
            required
          />
        </b-field>

        <!-- Bouton pour afficher les formations -->
        <div v-if="!isFormationsLoaded" class="buttons is-right">
          <b-button type="is-primary" @click="fetchFormations" :loading="isLoading">
            Voir les formations
          </b-button>
        </div>

        <!-- Sélection des formations uniquement si elles sont chargées -->
        <div v-if="isFormationsLoaded">
          <!-- Liste des formations avec cases à cocher -->
          <b-field label="Sélectionner les formations">
            <div class="formation-selection-container">
              <div
                v-for="formation in formations"
                :key="formation.uuid"
                class="formation-item"
              >
                <b-checkbox
                  v-model="selectedFormations"
                  :native-value="formation.uuid"
                >
                  {{ formation.label }}
                </b-checkbox>
              </div>
            </div>
          </b-field>

          <!-- Résumé des formations sélectionnées -->
          <div v-if="selectedFormations.length > 0" class="mt-4 mb-4">
            <p class="has-text-weight-bold">{{ selectedFormations.length }} formation(s) sélectionnée(s)</p>
          </div>

          <div class="buttons is-right">
            <b-button
              type="is-primary"
              native-type="submit"
              :loading="isLoading"
              :disabled="selectedFormations.length === 0"
            >
              Nettoyer Certificat
            </b-button>
          </div>
        </div>
      </form>
    </template>
  </AppToggleSection>
</template>

<script>
import APIAdmin from '@app/services/API/Admin';

export default {
  name: 'CertificateCleaner',
  data() {
    return {
      ownerEmail: '',
      studentEmail: '',
      isLoading: false,
      formations: [],
      selectedFormations: [],
      isFormationsLoaded: false,
    };
  },
  validate() {
    return {
      ownerEmail: {
        required: true,
        email: true,
      },
      studentEmail: {
        required: true,
        email: true,
      },
    };
  },
  watch: {
    ownerEmail() {
      this.resetFormations();
    },
    studentEmail() {
      this.resetFormations();
    },
  },
  methods: {
    resetFormations() {
      this.formations = [];
      this.selectedFormations = [];
      this.isFormationsLoaded = false;
    },
    fetchFormations() {
      APIAdmin.getFormationsForStudent({ owner_email: this.ownerEmail, student_email: this.studentEmail })
        .then((response) => {
          if (!response.data || response.data.length === 0) {
            this.$buefy.toast.open({
              message: 'Aucun certificat à nettoyer',
              type: 'is-warning',
            });
            this.isLoading = false;
          } else {
            this.formations = response.data;
            console.log(response);
            this.isFormationsLoaded = true;
          }
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des formations:', error);
          this.$buefy.toast.open({
            message: 'Erreur lors de la récupération des formations',
            type: 'is-danger',
          });
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    cleanSelectedFormations() {
      if (this.selectedFormations.length === 0) {
        this.$buefy.dialog.alert('Aucune formation sélectionnée pour le nettoyage du certificat.');
        return;
      }

      this.isLoading = true;
      const data = {
        owner_email: this.ownerEmail,
        student_email: this.studentEmail,
        trainings: this.selectedFormations,
      };
      APIAdmin.cleanFormationsCertificat(data)
        .then(() => {
          this.$buefy.dialog.alert('Les certificats des formations sélectionnées ont été nettoyés avec succès !');
          this.selectedFormations = [];
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: `Erreur ${error} lors du nettoyage des certificats`,
            type: 'is-danger',
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.formations = [];
          this.selectedFormations = [];
          this.isFormationsLoaded = false;
          this.ownerEmail = '';
          this.studentEmail = '';
        });
    },
  },
};
</script>

<style scoped>
.formation-selection-container {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
}

.formation-item {
  padding: 0.5rem;
  border-bottom: 1px solid #f5f5f5;
}

.formation-item:last-child {
  border-bottom: none;
}
</style>
