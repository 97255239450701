<template>
  <AppToggleSection>
    <template #title>
      Changement d'adresse email
    </template>
    <template #default>
      <form @submit.prevent="updateEmail">
        <b-field label="Ancien email">
          <b-input v-model="oldEmail" placeholder="ancien email" type="email" required />
        </b-field>
        <b-field label="Nouvel email">
          <b-input v-model="newEmail" placeholder="nouvel email" type="email" required />
        </b-field>
        <div class="buttons is-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour l'email
          </b-button>
        </div>
      </form>
    </template>
  </AppToggleSection>
</template>

<script>
import APIAdmin from '@app/services/API/Admin';

export default {
  name: 'EmailUpdate',
  data() {
    return {
      oldEmail: '',
      newEmail: '',
      isLoading: false,
    };
  },
  methods: {
    updateEmail() {
      this.isLoading = true;
      const data = { old_email: this.oldEmail, new_email: this.newEmail };

      APIAdmin.updateEmail(data)
        .then(() => {
          this.$buefy.dialog.alert(`
            Modification effectuée !
          `);
          this.oldEmail = '';
          this.newEmail = '';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
